import * as React from 'react';

import 'focus-visible';
import 'katex/dist/katex.min.css';

import './src/styles/tailwind.css';

import { GatsbyBrowser } from 'gatsby';

import { AppWrapper } from './src/app/Wrapper';

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  void (async () => {
    if (typeof IntersectionObserver === `undefined`) {
      await import('intersection-observer');
    }
    if (typeof URL === `undefined` || typeof URL === `object`) {
      await import('url-polyfill');
    }
  })();
};

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => <AppWrapper element={element} />;

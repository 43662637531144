module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Misapplied Math","short_name":"Misapplied Math","start_url":"/","background_color":"#ffffff","theme_color":"#60A5FA","display":"standalone","cache_busting_mode":"none","icon":"src/images/logos/misapplied.svg","icons":[{"src":"/icons/48x48.png","sizes":"48x48","type":"image/png","purpose":"any"},{"src":"/icons/72x72.png","sizes":"72x72","type":"image/png","purpose":"any"},{"src":"/icons/96x96.png","sizes":"96x96","type":"image/png","purpose":"any"},{"src":"/icons/144x144.png","sizes":"144x144","type":"image/png","purpose":"any"},{"src":"/icons/192x192.png","sizes":"192x192","type":"image/png","purpose":"any"},{"src":"/icons/256x256.png","sizes":"256x256","type":"image/png","purpose":"any"},{"src":"/icons/384x384.png","sizes":"384x384","type":"image/png","purpose":"any"},{"src":"/icons/512x512.png","sizes":"512x512","type":"image/png","purpose":"any"},{"src":"/icons/maskable/48x48.png","sizes":"48x48","type":"image/png","purpose":"maskable"},{"src":"/icons/maskable/72x72.png","sizes":"72x72","type":"image/png","purpose":"maskable"},{"src":"/icons/maskable/96x96.png","sizes":"96x96","type":"image/png","purpose":"maskable"},{"src":"/icons/maskable/144x144.png","sizes":"144x144","type":"image/png","purpose":"maskable"},{"src":"/icons/maskable/192x192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"/icons/maskable/256x256.png","sizes":"256x256","type":"image/png","purpose":"maskable"},{"src":"/icons/maskable/384x384.png","sizes":"384x384","type":"image/png","purpose":"maskable"},{"src":"/icons/maskable/512x512.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"theme_color_in_head":false,"legacy":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/index.html","/about.html","/articles.html","/projects.html","/articles/twelve-days-2013-de-bruijn-sequences/index.html","/articles/twelve-days-2013-shunting-yard-algorithm/index.html","/articles/twelve-days-2013-the-bellman-ford-algorithm/index.html","/articles/testing-collections-with-guava-testlib-and-junit-4/index.html","/articles/why-i-always-hit-the-crosswalk-button/index.html"],"workboxConfig":{"globPatterns":["**/icon-*"]}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
